<template lang="pug">
.home
  .container
    .box
      p.title Southern Illinois Disc Golf Club
      p.subtitle Helping disc golfers in Southern Illinois stay up to date!
  //- p.is-size-2 Events
  upcoming-events
  .container
    .box
      calendar
  .container
    .box.has-text-centered
      p.is-size-3 Join Us On Discord!
      hr
      a.button.is-info(href='https://discord.gg/hUkyRFJf9W') Southern Illinois Disc Golf Discord Server
</template>

<script setup>
import UpcomingEvents from '@/components/Events'
import Calendar from '@/views/Calendar'
</script>
